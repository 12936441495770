import React from "react"

import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import Img from "./Img"

import * as styles from "./odobrenaKarta.module.scss"
import { handleClickBanner } from "../../../helpers/WatcherDL/utils/handleClickBanner"

const buttonText = "Забрать свою карту"

export default function OdobrenaKarta() {
  return (
    <section className={styles.section}>
      <Img alt="bg image" className={styles.img} />
      <Container className={styles.container}>
        <div className={styles.text}>
          <h1>
            Карта рассрочки
            <br />
            ждет, пока ее заберут
          </h1>
          <p>Процент за вас платит магазин</p>
        </div>
        <Button className={styles.btn} onClick={() => handleClickBanner("click_cta")}>
          {buttonText}
        </Button>
      </Container>
    </section>
  )
}
