import sendRequest from "@ecom/ui/utils/sendRequest"

type Response = {
  status: string
  errorCode: number
  errorText?: string
  id?: string
}

export function fetchFormData(variables: Record<string, string>) {
  return sendRequest(`${process.env.GATSBY_APP_API_URL}/v2/app/public/callback`, variables).then(
    ({ status }: Response) => {
      if (["pending", "approve"].includes(status)) {
        return Promise.resolve(status)
      }

      return Promise.reject(new Error(status))
    }
  )
}
