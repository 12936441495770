import React from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/Layouts/mainPage"
import Banner from "../components/Banners/OdobrenaKarta"
import Discounts from "../components/Discounts"
import Conditions from "../components/Conditions"
import WaysToGetCard from "../components/WaysToGetCard"
import PhoneForm from "../components/PhoneForm"
import Partners from "../components/Partners"

import { PageData } from "../interfaces/pageProps"

import { getPageData } from "../helpers/getPageData"

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, phones, noIndex } = getPageData(data)
  const { halvaOstatok } = data.admin

  if (!halvaOstatok) {
    throw new Error("The halvaOstatok variable is required!!!")
  }

  return (
    <Layout ligal={ligal} phones={phones} noIndex={noIndex}>
      <Banner />
      <Discounts />
      <Conditions rate={halvaOstatok.value} />
      <WaysToGetCard />
      <PhoneForm />
      <Partners />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/vam-odobrena-karta-rassrochki/") {
        url
        phones
        ligal {
          text
        }
        notIndex
      }
      halvaOstatok: variable(name: "halvaOstatok") {
        value
      }
    }
  }
`
